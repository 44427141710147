@media print {
  html,
  body {
    font-size: 10.5pt;
  }
  .table {
    color: #000000;
  }
  hr {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .MeerkampPrestatie.table-sm th,
  .MeerkampPrestatie.table-sm td {
    padding: 0.2rem 0 0.2rem 0;
  }
  .RanglijstSectie {
    page-break-before: always;
    max-width: 500px;
  }
  .RanglijstSectie h3 {
    display: none;
  }
}

