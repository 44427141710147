/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1023px)  {
  /* Force table to not be like tables anymore */
  table.Ranglijst {
    display: block;

    thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr { border: 1px solid #ccc; }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    /*
    Label the data
    */
    td:before { text-align: left }

    td:nth-of-type(1):before { content: "Afstand"; }
    td:nth-of-type(2):before { content: "Naam"; }
    td:nth-of-type(3):before { content: "Prestatie"; }
    td:nth-of-type(4):before { content: "Plaats"; }
    td:nth-of-type(5):before { content: "Datum"; }

    td.TechnischNummerCell:not(.MeerkampCell) {
      &:before { content: "Onderdeel"; }
    }
  }

  .MeerkampCell {
    position: relative;

    hr,
    .MeerkampPrestatie {
      position: relative;
      right: 100%;
      width: 200%;
    }

    .MeerkampPrestatie {
      td:nth-of-type(1):before { content: "Onderdeel"; }
      td:nth-of-type(2):before { content: "Prestatie"; }
    }
  }
}
