$container-max-widths: (
       sm: 720px,
       lg: 820px,
) !default;

@import "../node_modules/bootstrap/scss/bootstrap";

h1 {
  padding-top: 3rem;
}

.RanglijstSectie {
  margin-bottom: 2.5rem;
  overflow: auto;
}

.Ranglijst table.table tbody {
  tr {
    background: none;
  }
}

.FilterButton.btn:not(.active) {
  &:active,
  &:hover {
    background-color: white;

    &.btn-outline-success {
      color: $success;
    }

    &.btn-outline-primary {
      color: $primary;
    }

    &.btn-outline-secondary {
      color: $secondary;
    }
  }
}
